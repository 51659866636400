import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NavLink = props => {
  if (!props.test) {
    return <Link to={props.url}>{props.text}</Link>;
  } else {
    return <span>{props.text}</span>;
  }
};

const BlogPage = ({ data, pageContext }) => {
  const { group, index, first, last } = pageContext;
  const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  return (
    <Layout>
      <SEO
        title="Blog"
      />

      <ul className="entries">
        {group.map(({ node }) => (
          <li key={node.slug} className="one-entry">
            <Link to={`/blog/${node.slug}`} >
              <h2 className="entry-title"
                dangerouslySetInnerHTML={{ __html: node.title }}
              />
            </Link>

            <div
              dangerouslySetInnerHTML={{ __html: node.excerpt }}
            />
          </li>
        ))}
      </ul>

      {data.allWordpressPost.totalCount > 3 ? (
        <div className="pagination">
          <div className="previousLink">
            <NavLink test={first} url={"/blog/" + previousUrl} text="&#8592;" />
          </div>
          <div className="nextLink">
            <NavLink test={last} url={"/blog/" + nextUrl} text="&#8594;" />
          </div>
        </div>
      ) : null
      }

    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query {
    allWordpressPost {
      totalCount
      edges {
        node {
          title
          excerpt
          slug
        }
      }
    }
  }
`